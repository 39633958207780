import { ILanguage } from '@wix/thunderbolt-symbols'

const ALL_LANGUAGE_CODES = <const>{
	ar: 'ar',
	bg: 'bg',
	zh: 'zh',
	cs: 'cs',
	da: 'da',
	nl: 'nl',
	fi: 'fi',
	fr: 'fr',
	de: 'de',
	el: 'el',
	he: 'he',
	hi: 'hi',
	hu: 'hu',
	id: 'id',
	it: 'it',
	ja: 'ja',
	jp: 'ja',
	ko: 'ko',
	kr: 'ko',
	lt: 'lt',
	ms: 'ms',
	no: 'no',
	pl: 'pl',
	pt: 'pt',
	ro: 'ro',
	ru: 'ru',
	es: 'es',
	sv: 'sv',
	tl: 'tl',
	th: 'th',
	tr: 'tr',
	uk: 'uk',
}

const languageLoader: Record<keyof typeof ALL_LANGUAGE_CODES, () => Promise<any>> = {
	ar: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ar.json' /* webpackChunkName: "santa-langs-ar" */
		),
	bg: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_bg.json' /* webpackChunkName: "santa-langs-bg" */
		),
	zh: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_zh.json' /* webpackChunkName: "santa-langs-zh" */
		),
	cs: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_cs.json' /* webpackChunkName: () =>"santa-langs-cs" */
		),
	da: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_da.json' /* webpackChunkName: () =>"santa-langs-da" */
		),
	nl: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_nl.json' /* webpackChunkName: () =>"santa-langs-nl" */
		),
	fi: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_fi.json' /* webpackChunkName: () =>"santa-langs-fi" */
		),
	fr: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_fr.json' /* webpackChunkName: () =>"santa-langs-fr" */
		),
	de: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_de.json' /* webpackChunkName: () =>"santa-langs-de" */
		),
	el: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_el.json' /* webpackChunkName: () =>"santa-langs-el" */
		),
	he: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_he.json' /* webpackChunkName: () =>"santa-langs-he" */
		),
	hi: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_hi.json' /* webpackChunkName: () =>"santa-langs-hi" */
		),
	hu: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_hu.json' /* webpackChunkName: () =>"santa-langs-hu" */
		),
	id: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_id.json' /* webpackChunkName: () =>"santa-langs-id" */
		),
	it: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_it.json' /* webpackChunkName: () =>"santa-langs-it" */
		),
	ja: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ja.json' /* webpackChunkName: () =>"santa-langs-ja" */
		),
	jp: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ja.json' /* webpackChunkName: () =>"santa-langs-ja" */
		),
	ko: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ko.json' /* webpackChunkName: () =>"santa-langs-ko" */
		),
	kr: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ko.json' /* webpackChunkName: () =>"santa-langs-ko" */
		),
	lt: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_lt.json' /* webpackChunkName: () =>"santa-langs-lt" */
		),
	ms: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ms.json' /* webpackChunkName: () =>"santa-langs-ms" */
		),
	no: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_no.json' /* webpackChunkName: () =>"santa-langs-no" */
		),
	pl: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_pl.json' /* webpackChunkName: () =>"santa-langs-pl" */
		),
	pt: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_pt.json' /* webpackChunkName: () =>"santa-langs-pt" */
		),
	ro: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ro.json' /* webpackChunkName: () =>"santa-langs-ro" */
		),
	ru: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ru.json' /* webpackChunkName: () =>"santa-langs-ru" */
		),
	es: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_es.json' /* webpackChunkName: () =>"santa-langs-es" */
		),
	sv: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_sv.json' /* webpackChunkName: "santa-langs-sv" */
		),
	tl: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_tl.json' /* webpackChunkName: () =>"santa-langs-tl" */
		),
	th: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_th.json' /* webpackChunkName: () =>"santa-langs-th" */
		),
	tr: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_tr.json' /* webpackChunkName: () =>"santa-langs-tr" */
		),
	uk: () =>
		import(
			'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_uk.json' /* webpackChunkName: "santa-langs-uk" */
		),
}

export const getTranslation = (userLanguage: ILanguage['userLanguage']) => {
	if (userLanguage in languageLoader) {
		// @ts-ignore
		const languageLoaderStringRecord: Record<string, () => Promise<any>> = languageLoader
		return languageLoaderStringRecord[userLanguage.toString()]()
	}

	return import(
		'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_en.json' /* webpackChunkName: "santa-langs-en" */
	)
}
